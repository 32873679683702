import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Typography } from '@material-ui/core';

import { ItemsConsumer, UsersConsumer } from './ReceiptProvider';
import ReceiptUser from './ReceiptUser';

const useStyles = makeStyles(theme => ({
  usersBox: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
  usersPaper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    display: 'inline-flex',
    flexWrap: 'nowrap',
  },
}));

export default function ReceiptUsers() {
  const classes = useStyles();

  return (
    <Box my={4} px={4} className={classes.usersBox}>
      <Paper className={classes.usersPaper}>
        <ItemsConsumer>
          {({ items, editItem, removeUserFromItems }) => (
            <UsersConsumer>
              {({ users, editUser, removeUser }) => (
                users.map((user, index) => {
                  return <ReceiptUser
                    items={items}
                    onEditItem={editItem}
                    onRemoveUserFromItems={removeUserFromItems}
                    user={user}
                    onEditUser={editUser}
                    onRemoveUser={removeUser}
                    key={index}
                  />
                })
              )}
            </UsersConsumer>
          )}
        </ItemsConsumer>
      </Paper>
    </Box>
  )
}
