import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Paper, Collapse,
  Checkbox, IconButton, Button, Typography
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { UsersConsumer } from './ReceiptProvider';
import ReceiptItemAvatar from './ReceiptItemAvatar';
import ReceiptItemAddUser from './ReceiptItemAddUser';
import ReceiptItemEdit from './ReceiptItemEdit';
import ReceiptItemDelete from './ReceiptItemDelete';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    // borderBottom: '1px solid #EEEEEE',
    // margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    margin: `0 ${theme.spacing(4)}px`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemPaper: {
    borderRadius: '16px',
  },
  itemText: {
    fontWeight: 'bold',
  },
  itemPrice: {
    color: '#777777',
  },
  itemPanel: {
    boxShadow: 'none',
    marginTop: '0 !important',
    '&:before': {
      content: 'none',
    },
  },
  itemPanelSummary: {
    display: 'none',
  },
  itemPanelDetails: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px 0`,
  },
}));

export default function ReceiptItem({ item, onEditItem, onDelete }) {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);
  const [itemExpanded, setItemExpanded] = React.useState(false);

  const [open, setOpen] = React.useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleOpen = name => {
    setOpen({ ...open, [name]: true });
  };
  const handleClose = name => {
    setOpen({ ...open, [name]: false });
  };

  return (
    <Grid item xs={12} className={classes.itemContainer}>
      <Paper className={classes.itemPaper}>
        <Box py={4} px={2}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Box pl={2}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  color="primary"
                />
              </Box>
            </Grid>
            <Grid item xs={8} onClick={() => setItemExpanded(!itemExpanded)}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.itemText}>{item.item}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.itemPrice}>${item.price}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} onClick={() => setItemExpanded(!itemExpanded)}>
              <IconButton aria-label="more">
                {itemExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={itemExpanded} timeout="auto" unmountOnExit>
            <Box mt={2} px={4}>
              <Box mb={2}>
                <Grid container>
                  <UsersConsumer>
                    {({ users }) => (
                      users.map((user, index) => {
                        if (item.users.includes(user.id)) {
                          return <ReceiptItemAvatar user={user} item={item} onDelete={onEditItem} key={user.id} />
                        }
                      })
                    )}
                  </UsersConsumer>
                </Grid>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    startIcon={<PersonAddIcon />}
                    onClick={() => handleOpen('add')}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    startIcon={<EditIcon />}
                    onClick={() => handleOpen('edit')}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    startIcon={<DeleteIcon />}
                    onClick={() => handleOpen('delete')}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <UsersConsumer>
            {({ users, addUser }) => (
              <ReceiptItemAddUser
                open={open.add}
                onClose={() => handleClose('add')}
                onEditItem={onEditItem}
                onAddUser={addUser}
                item={item}
                users={users}
              />
            )}
          </UsersConsumer>
          <ReceiptItemEdit
            open={open.edit}
            onEditItem={onEditItem}
            onClose={() => handleClose('edit')}
            item={item}
          />
          <ReceiptItemDelete
            open={open.delete}
            onDelete={onDelete}
            onClose={() => handleClose('delete')}
            item={item}
          />
        </Box>
      </Paper>
    </Grid>
  )
}
