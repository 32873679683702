import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Paper, Typography, Avatar, Button, IconButton, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { TotalConsumer } from './ReceiptProvider';
import ReceiptColorPicker from './ReceiptColorPicker';
import ReceiptUserItems from './ReceiptUserItems';

const WIDTH = 120;

export default function ReceiptUser({ items, onEditItem, onRemoveUserFromItems,
                                      user, userTotals, onEditUser, onRemoveUser }) {
  const [tempColor, setTempColor] = React.useState(user.color);

  const useStyles = makeStyles(theme => ({
    userWrapper: {
      '&:first-of-type': {
        paddingLeft: theme.spacing(4),
      },
    },
    userPaper: {
      backgroundColor: colors[user.color][50],
      border: `1px solid ${colors[user.color][500]}`,
      borderRadius: '20px',
      cursor: 'pointer',
      // height: WIDTH,
      minWidth: WIDTH,
    },
    userAvatar: {
      backgroundColor: colors[user.color][500],
      color: theme.palette.getContrastText(colors[user.color][500]),
    },
    userPriceText: {
      fontSize: '24px',
    },
    userNameText: {
      fontSize: '22px',
      marginTop: '8px',
    },
    userDialog: {
      '& .MuiPaper-root': {
        minWidth: '90vw',
      },
    },
    userDialogTitle: {
      backgroundColor: colors[tempColor][500],
      color: theme.palette.getContrastText(colors[tempColor][500]),
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    userDialogName: {
      paddingTop: theme.spacing(2),
    },
    userEditName: {
      '& > .MuiInput-root': {
        color: theme.palette.getContrastText(colors[tempColor][500]),
        '&:before': {
          borderColor: theme.palette.getContrastText(colors[tempColor][500]),
        }
      }
    },
    userDialogIcons: {
      color: theme.palette.getContrastText(colors[tempColor][500]),
    },
    userDialogSave: {
      backgroundColor: colors[tempColor][500],
      color: theme.palette.getContrastText(colors[tempColor][500]),
    },
    userDialogCancel: {
      color: colors[tempColor][500],
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(user.name);
  const [selectColor, setSelectColor] = React.useState(false);

  const handleName = () => event => {
    setName(event.target.value);
  }

  const handleDelete = () => {
    onRemoveUserFromItems(user);
    onRemoveUser(user);
    setOpen(false);
  }

  const handleSave = () => {
    onEditUser({
      ...user,
      name: name,
      color: tempColor,
    });
    setOpen(false);
  }

  const handleClose = () => {
    setName(user.name);
    setTempColor(user.color);
    setOpen(false);
  }

  return (
    <Box py={4} pr={4} className={classes.userWrapper}>
      <Paper onClick={() => setOpen(true)} className={classes.userPaper}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Avatar alt={user.name} className={classes.userAvatar}>{user.name[0]}</Avatar>
            </Grid>
            <Grid item xs={6}>
              <TotalConsumer>
                {({ userTotals }) => (
                  <Typography className={classes.userPriceText}>${userTotals(user).total}</Typography>
                )}
              </TotalConsumer>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.userNameText}>{user.name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="user-dialog-title"
        className={classes.userDialog}
      >
        <DialogTitle id="add-user-dialog-title" className={classes.userDialogTitle}>
          <Grid container>
            <Grid item xs={8} className={classes.userDialogName}>
              <TextField
                id="edit-user-name"
                placeholder="Name"
                name="edit-user-name"
                value={name}
                onChange={handleName()}
                fullWidth
                className={classes.userEditName}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => setSelectColor(true)} className={classes.userDialogIcons}>
                <EditIcon />
              </IconButton>
              {selectColor ?
                <ReceiptColorPicker
                  selectedColor={tempColor}
                  onSelect={setTempColor}
                  onClose={() => setSelectColor(false)}
                />
                :
                ''
              }
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleDelete()} className={classes.userDialogIcons}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.userDialogContent}>
          <TotalConsumer>
            {({ userTotals }) => (
              <ReceiptUserItems items={items} user={user} userTotals={userTotals(user)} />
            )}
          </TotalConsumer>
        </DialogContent>
        <DialogActions>
          {/* TODO: Button ripple color */}
          <Button onClick={() => handleSave()} variant="contained" className={classes.userDialogSave} fullWidth autoFocus>
            Save
          </Button>
          <Button onClick={() => handleClose()} className={classes.userDialogCancel} fullWidth>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
