import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, Box, TextField, Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  editDialog: {
    '& .MuiPaper-root': {
      margin: '48px 12px',
    },
  },
  editDialogContent: {
    padding: theme.spacing(6),
  },
}));

export default function ReceiptItemEdit({ open, onEditItem, onClose, item }) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    item: item.item,
    price: item.price,
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    onEditItem({ ...item, ...values });
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-dialog-title"
      className={classes.editDialog}
    >
      <DialogTitle id="edit-dialog-title">Edit</DialogTitle>
      <DialogContent className={classes.editDialogContent}>
        <Grid container>
          <Grid item xs={9}>
            <Box pr={2}>
              <TextField
                id="item-name"
                label="Item"
                name="item-name"
                value={values.item}
                onChange={handleChange('item')}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box pl={2}>
              <TextField
                id="item-price"
                label="Price"
                name="item-price"
                value={values.price}
                onChange={handleChange('price')}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()} color="primary" variant="contained" fullWidth autoFocus>
          Save
        </Button>
        <Button onClick={onClose} color="primary" fullWidth>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
