import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Avatar, IconButton } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import CloseIcon from '@material-ui/icons/Close';

export default function ReceiptItemAvatar({ user, item, highlight, onSelect, onDelete }) {
  const useStyles = makeStyles(theme => ({
    avatar: {
      backgroundColor: colors[user.color][500],
      borderRadius: '40px',
      boxShadow: highlight ? `inset 0 0 0 3px ${colors[user.color][900]}` : 'none',
      color: theme.palette.getContrastText(colors[user.color][500]),
      transition: '.2s ease',
      // width: 'unset',
    },
    avatarDelete: {
      width: '80px',
    },
    name: {
      left: 0,
      padding: '13px',
      position: 'absolute',
    },
    nameDelete: {
      // position: 'absolute',
    },
    delete: {
      // boxShadow: `0 0 1px 2px ${colors[user.color][900]}`,
      boxShadow: `0 0 0 2px ${theme.palette.primary.contrastText}`,
      color: theme.palette.getContrastText(colors[user.color][500]),
      padding: '8px',
      position: 'absolute',
      right: 0,
      transition: '.2s ease',
    },
    hideDelete: {
      color: 'transparent',
      boxShadow: 'none',
    },
  }));

  const classes = useStyles();

  const [showDelete, setShowDelete] = React.useState(false);
  const [deleteAnim, setDeleteAnim] = React.useState(false);

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
    setTimeout(() => {
      setDeleteAnim(!deleteAnim);
    }, 1)
  }

  const handleDelete = () => {
    setTimeout(() => {
      onDelete({
        ...item,
        users: item.users.filter(id => id !== user.id)
      })
    }, 200)
  }

  return (
    <Grid item>
      <Box m={2}>
        <Avatar
          alt={user.name}
          // className={classes.avatar}
          className={classes.avatar + (deleteAnim ? ` ${classes.avatarDelete}` : '')}
          // onClick={onClick}
          onClick={onSelect || handleShowDelete}
        >
          <div className={classes.name + (deleteAnim ? ` ${classes.nameDelete}` : '')}>
            {user.name[0]}
          </div>
          {showDelete ?
            <IconButton
              onClick={() => handleDelete()}
              aria-label="remove-user"
              className={classes.delete + (!deleteAnim ? ` ${classes.hideDelete}` : '')}
            >
              <CloseIcon />
            </IconButton>
            :
            ''
          }
        </Avatar>
      </Box>
    </Grid>
  )
}
