import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, Box, Paper, TextField, Button, Typography, Avatar,
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';

import ReceiptItemAvatar from './ReceiptItemAvatar';
import ReceiptColorPicker from './ReceiptColorPicker';

const useStyles = makeStyles(theme => ({
  addUserDialog: {
    '& .MuiPaper-root': {
      margin: '48px 12px',
    },
  },
  addUserDialogContent: {
    padding: theme.spacing(6),
  },
  fadedText: {
    color: '#888888',
  },
  colorsWrapper: {
    // margin: '0 24px 0 0 !important',
    // position: 'absolute',
    boxShadow: '0 0 0 2000px rgba(0,0,0,0.5)',
    left: 0,
    margin: '0 24px !important',
    position: 'fixed',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  anotherUser: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export default function ReceiptItemAddUser({ open, onClose, onEditItem, onAddUser, item, users }) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    name: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [selectColor, setSelectColor] = React.useState(false);
  const [avatarColor, setAvatarColor] = React.useState('red');

  const handleAvatar = id => {
    onEditItem({
      ...item,
      users: item.users.includes(id) ?
        item.users.filter(userId => userId !== id) : [ ...item.users, id ]
    });
  }

  const handleSave = () => {
    let newUser;
    if (values.name) {
      newUser = onAddUser({ name: values.name, color: avatarColor });
    }
    if (newUser) {
      onEditItem({
        ...item,
        users: [ ...item.users, newUser ],
      });
    }
    setValues({ ...values, name: '' });
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-user-dialog-title"
      className={classes.addUserDialog}
    >
      <DialogTitle id="add-user-dialog-title">Add Person</DialogTitle>
      <DialogContent className={classes.addUserDialogContent}>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Grid container>
              {users.map((user, index) => {
                return <ReceiptItemAvatar
                          user={user}
                          highlight={item.users.includes(user.id)}
                          onSelect={() => handleAvatar(user.id)}
                          key={user.id}
                        />
              })}
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<PeopleIcon />}
              fullWidth
            >
              From Friends List
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box py={3}>
              <Typography align="center" className={classes.fadedText}>or</Typography>
            </Box>
          </Grid> */}
          <Grid item xs={10}>
            <TextField
              id="add-user-name"
              label="Name"
              name="add-user-name"
              value={values.name}
              onChange={handleChange('name')}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Box mt={1}>
              <Avatar onClick={() => setSelectColor(true)} style={{ backgroundColor: colors[avatarColor][500] }}>
                <EditIcon />
              </Avatar>
            </Box>
          </Grid>
          {selectColor ?
            <ReceiptColorPicker
              selectedColor={avatarColor}
              onSelect={setAvatarColor}
              onClose={() => setSelectColor(false)}
            />
            :
            ''
          }
        </Grid>
        <Grid item xs={12}>
          <Box mt={4}>
            <Typography align="center" className={classes.anotherUser}>Add another user</Typography>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()} color="primary" variant="contained" fullWidth autoFocus>
          Save
        </Button>
        <Button onClick={onClose} color="primary" fullWidth>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
