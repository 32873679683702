import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import { ItemsConsumer } from './ReceiptProvider';
import ReceiptItem from './ReceiptItem';
import ReceiptAddItem from './ReceiptAddItem';

const useStyles = makeStyles(theme => ({
  itemsBackground: {
    backgroundColor: '#edf1f7',
  },
}));

export default function ReceiptItems() {
  const classes = useStyles();

  return (
    <Box pt={18} pb={32} className={classes.itemsBackground}>
      <Grid container>
        <ItemsConsumer>
          {({ items, editItem, removeItem }) => (
            items.map(item => {
              return <ReceiptItem item={item} onEditItem={editItem} onDelete={removeItem} key={item.id} />
            })
          )}
        </ItemsConsumer>
      </Grid>
      <ReceiptAddItem />
    </Box>
  )
}
