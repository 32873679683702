import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));

export default function ReceiptItemDelete({ open, onDelete, onClose, item }) {
  const classes = useStyles();

  const handleDelete = () => {
    onDelete(item);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
    >
      <DialogTitle id="delete-dialog-title">Are you sure you want to delete this?</DialogTitle>
      <DialogActions>
        <Button onClick={() => handleDelete()} color="secondary" variant="contained" fullWidth autoFocus>
          Yes
        </Button>
        <Button onClick={onClose} color="primary" fullWidth>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
